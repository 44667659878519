<template>
  <div class="customerServiceVer">
    <van-nav-bar
      title="黑号查询"
      left-arrow
      :safe-area-inset-top="true"
      @click-left="onClickLeft"
      :fixed="true"
      :border="true"
      :placeholder="true"
      v-show="is_navbar === 'true'"
    />
    <div class="customerServiceVerBody">
      <div class="customerServiceVerCenter">
        <van-field
          v-model="text"
          placeholder="请输入需要查询的号码"
          center
          clearable
        />
        <div class="queryButton" @click="goQuery">查询</div>
        <div class="tipsContainer">
          <div class="tipTitle">什么是黑号查询</div>
          <van-divider class="tipLine" />
          <div class="tipsText">
            输入交易对象的QQ、游戏QQ、支付宝、微信、身份证号显示异常，说明该用户存在诈骗或找回！不建议继续交易！
          </div>
        </div>
      </div>
    </div>
    <SuccessDialog
      :content="dialogContent"
      :title="dialogTitle"
      :iconType="iconType"
      ref="SuccessDialog"
    ></SuccessDialog>
  </div>
</template>

<script>
import { getVerifySeller } from "@/api/security";
import { SuccessDialog } from "@/components";
export default {
  name: "BlacklistQuery",
  data() {
    return {
      text: "",
      dialogContent: "",
      dialogTitle: "",
      iconType: "",
      is_navbar: "true",
    };
  },
  components: {
    SuccessDialog,
  },
  mounted() {
    if (JSON.stringify(this.$route.query) != "{}") {
      this.is_navbar = this.$route.query.is_navbar || "true";
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    goQuery() {
      if (this.text === "") {
        this.$toast("请输入内容进行查询");
        return;
      }
      let config = {
        keyword: this.text,
      };
      this.requestGetVerifySeller(config);
    },
    async requestGetVerifySeller(config) {
      try {
        const result = await getVerifySeller(config);
        if (result.code === 20000 || result.status_code === 1000) {
          this.dialogContent =
            result.data.verify_pass === 1
              ? "此号码未受过拉黑处理，无违规记录"
              : "此号码在平台内发生过违规行为，已被拉黑处理";
          this.iconType = result.data.verify_pass;
          this.dialogTitle =
            result.data.verify_pass === 1 ? "无违规记录" : "存在违规记录";
          this.$refs.SuccessDialog._data.show = true;
        } else {
          this.$toast(result.message);
        }
      } catch (error) {
        this.$toast("请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.customerServiceVer {
  height: 100vh;
  .customerServiceVerBody {
    display: flex;
    justify-content: center;
    .customerServiceVerCenter {
      width: 335px;
      padding-top: 100px;
      .queryButton {
        width: 100%;
        height: 44px;
        background: #00a8ff 100%;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 44px;
        text-align: center;
        border-radius: 15px;
        margin-top: 20px;
      }
      .tipsContainer {
        padding-top: 100px;
        .tipTitle {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          line-height: 22px;
        }
        .tipLine {
          margin: 12px 0px;
        }
        .tipsText {
          height: 40px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #888888;
          line-height: 20px;
        }
      }
    }
  }
}

::v-deep .van-nav-bar .van-icon {
  color: black;
}
::v-deep .van-cell {
  background: #f5f5f5;
  border-radius: 15px;
}
::v-deep .van-field__control {
  text-align: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
</style>
